import { Component } from 'react';
import PropTypes from 'prop-types';

import withComponentName from '../../../decorators/withComponentName';

import Styles from '../styles.module.scss';

const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  initialPage: 1,
  pageSize: 10,
};

class MeetupPaginationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { pager: {}, tabletPage: 2 };
  }

  componentDidMount() {
    const { items, initialPage } = this.props;
    if (items && items.length) {
      this.setPage(initialPage, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { items, initialPage } = this.props;
    if (items !== prevProps.items) {
      this.setPage(initialPage, true);
    }
  }

  setPage(page, isInitial) {
    const { items, pageSize, onChangePage } = this.props;
    let { pager } = this.state;

    if (page < 1 || page > pager.totalPages) { return; }

    pager = this.getPager(items.length, page, pageSize);

    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    this.setState({ pager });

    onChangePage(pageOfItems, true, isInitial);
  }

  setPageTablet(tabletPage) {
    const { items, pageSize, onChangePage } = this.props;
    let { pager } = this.state;

    if (tabletPage < 1 || tabletPage > pager.totalPages) { return; }

    pager = this.getPager(items.length, tabletPage, pageSize);

    const pageOfItems = items.slice(0, pager.endIndex + 1);

    this.setState({ pager });

    onChangePage(pageOfItems, false);

    this.setState({ tabletPage: tabletPage + 1 });
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;

    pageSize = pageSize || 10;

    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage;
    let endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    const pages = [...Array((endPage + 1) - startPage).keys()].map((i) => startPage + i);

    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  render() {
    const { pager, tabletPage } = this.state;

    if (!pager.pages || pager.pages.length <= 1) { return null; }

    return (
      <div>
        <ul className={Styles.pagination}>
          {pager.pages.map((page, index) => (
            <li key={index} className={Styles[pager.currentPage === page ? 'active' : 'inactive']}>
              <a onClick={() => this.setPage(page)}>{page}</a>
            </li>
          ))}
        </ul>
        <span className={Styles['load-more']} onClick={() => this.setPageTablet(tabletPage)}> load more </span>
      </div>
    );
  }
}

MeetupPaginationComponent.propTypes = propTypes;
MeetupPaginationComponent.defaultProps = defaultProps;
export default withComponentName(MeetupPaginationComponent);
