import Styles from './styles.module.scss';

import withComponentName from '../../decorators/withComponentName';

function SpinnerLoaderComponent() {
  return (
    <div className={Styles.container}>
      <div className={Styles.spinner} />
    </div>
  );
}

export default withComponentName(SpinnerLoaderComponent);
