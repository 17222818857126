import { createRef, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons/faCloudDownloadAlt';

import withComponentName from '../../../decorators/withComponentName';

import MeetupPaginationComponent from './MeetupPaginationComponent';
import SpinnerLoaderComponent from '../../SpinnerLoaderComponent';

import MeetupSvc from '../../../services/dbServices/MeetupSvc';

import Styles from '../styles.module.scss';

class MeetupPreviousSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetups: [],
      pageOfItems: [],
      loaded: false,
    };
    this.eventsList = createRef();

    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    this.getMeetups();
  }

  onChangePage(pageOfItems, desktop, isInitial) {
    this.setState({ pageOfItems });
    if (desktop && !isInitial) {
      this.eventsList.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getMeetups() {
    const today = new Date();
    const previousDay = new Date(today);
    previousDay.setDate(previousDay.getDate() - 1);
    MeetupSvc.getMeetup({
      endDate: previousDay,
    }).then((meetups) => {
      this.setState({
        meetups,
        loaded: true,
      });
    });
  }

  render() {
    const { meetups, loaded, pageOfItems } = this.state;

    return (
      <div className={Styles['meetup-previous-section']}>
        <div className={Styles.section}>
          <h2>PREVIOUS EVENTS</h2>
          <div className={Styles['events-block']} ref={this.eventsList}>
            <div className={Styles.header}>
              <div className={Styles.column}>
                <span>Events</span>
              </div>
              <div className={Styles['flex-column']}>
                <div className={Styles['internal-column']}>
                  <span>TOPICS DISCUSSED</span>
                </div>
                <div className={Styles['internal-column']}>
                  <span>Presentations</span>
                </div>
              </div>
            </div>
            {loaded ? (
              pageOfItems.map((meetup) => (
                <div className={Styles.event} key={meetup.id}>
                  <div className={Styles.column}>
                    <div className={Styles['event-photo']}>
                      <img src={meetup.photo} alt="" />
                    </div>
                    <div className={Styles['event-main-info']}>
                      <span className={Styles['event-name']}>{meetup.title}</span>
                      <div className={Styles['event-status']}>
                        {Boolean(meetup.location) && (
                          <span className={Styles.status}>{meetup.location}</span>
                        )}
                        <span>{meetup.date}</span>
                      </div>
                    </div>
                  </div>
                  <div className={Styles['flex-column']}>
                    <div className={Styles['internal-column']}>
                      <div className={Styles['event-topics']}>
                        <span className={Styles.topics}>{meetup.topics_to_disscuss}</span>
                        {Boolean(meetup.link_to_cityfalcon_watchlist) && (
                          <a href={meetup.link_to_cityfalcon_watchlist}>See Watchlist</a>
                        )}
                      </div>
                    </div>
                    <div className={Styles['internal-column']}>
                      {meetup.presentations.length ? (
                        meetup.presentations.map((presentation, i) => (
                          <a href={presentation.file} className={Styles['event-presentation']} key={i} download>
                            <FontAwesomeIcon icon={faCloudDownloadAlt} />
                            <span>{presentation.name}</span>
                          </a>
                        ))
                      ) : (
                        <div className={Styles['event-presentation']} style={{ opacity: '0.6' }}>
                          <FontAwesomeIcon icon={faCloudDownloadAlt} />
                          <span>No presentation</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={Styles.spinner_container}>
                <SpinnerLoaderComponent />
              </div>
            )}
          </div>
          <MeetupPaginationComponent
            items={meetups}
            onChangePage={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}

export default withComponentName(MeetupPreviousSection);
