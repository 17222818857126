import { RetryAPICall } from '../../helpers/apiHelpers';
import API from './api';

export default class MeetupSvc {
  static async getMeetup(options = {}) {
    try {
      const params = {
        start_datetime: options?.startDate?.toISOString(),
        end_datetime: options?.endDate?.toISOString(),
        meetup_location: options?.location,
      };

      const { data } = await RetryAPICall(API, 'meetups', {
        params,
      });

      return Array.isArray(data.meetups) ? (
        data.meetups.map((meetup, i) => ({
          id: i,
          ...meetup,
        }))
      ) : [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  }
}
